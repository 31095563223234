<template>
  <v-container id="case-index-container" class="fill-height" fluid>
    <section-header :title="$t('testCases')" :actionText="$t('createTestCase')"></section-header>
    <CaseManagement
      @folder-select="onSelectFolder"
      @bulkRemove="onBulkRemove"
      @caseRemove="onCaseRemove"
      :quick-create="true"
      :cases="cases"
    >
    </CaseManagement>
  </v-container>
</template>

<script>
import SectionHeader from '@/components/TestCases/SectionHeader.vue';
import CaseManagement from '@/components/Cases/CaseManagement'
import makeCasesService from '@/services/api/case';
import { showErrorToast,showSuccessToast } from '@/utils/toast';
export default {
  components: {
    SectionHeader,
    CaseManagement
  },
  data(){
    return {
      cases: []
    }
  },
  methods:{
    async onSelectFolder(folderUID = null) {
      // Avoid Double Redirect
      await this.getCases(folderUID);

      if(this.$route.name == "CasesFolder" && this.$route.params.folderUid == folderUID) 
        return;

      if(folderUID) {
        if (folderUID !== this.selectedFolderUid) {
          this.selectedFolderUid = folderUID
          this.$router.push(
            {
              name: 'CasesFolder',
              params: {
                folderUid: folderUID
              },
            }
          )
        }
      }else {
        folderUID = this.selectedFolderUid
      }
    },
    async getCases(folderUID){
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      await caseService.getFolderCases(handle, projectKey, folderUID).then(response => {
          this.cases = response.data.cases;

      }).catch(err => {
        console.log(err)
      })
    },
    async onCaseRemove(uid){
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      await caseService.deleteCase( handle, projectKey, uid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.caseDeleted'));
        this.cases = this.cases.filter(item => item.uid !== uid);
      }).catch(err => {
        showErrorToast(this.$swal, this.$t('error.caseDeleteFailed'));
        console.log(err)
      })
    },
    async onBulkRemove(payload){
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      await caseService.deleteCases(handle, projectKey, payload).then(() =>{
        this.cases = this.cases.filter(item => !payload.ids.includes(item.uid));
        showSuccessToast(this.$swal, this.$t('success.caseBulkRemoveSuccess'));
      }).catch(err => {
        showErrorToast(this.$t('error.caseBulkRemoveFailed'));
        console.error("Error: ", err);
      })
      
    },
  },
}
</script>
<style>
  .position-relative{
    position: relative !important;
  }
</style>
